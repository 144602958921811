.step2Wrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mediaChoiceBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  margin: 10px 30px 0px 30px;
}
.monthSelectWrapper {
  width: 200px;
  text-align: center;
  margin-bottom: 200px;
  position: absolute;
  bottom: -25px;
  left: -11px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.presenterBtn {
  position: relative;
}
.slaveBtn {
  position: absolute;
  top: -80px;
}
.slaveBtn2 {
  position: absolute;
  top: 80px;
}
.slaveBtn3 {
  position: absolute;
  top: 160px;
}
.link {
  text-decoration: none;
}
