.step3Wrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chartsWrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 30px 0px 30px;
  width: 1000px;
}
.investment_growth_chart_wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  padding: 3px;
  position: relative;
}
.investment_growth_chart_wrapper::after {
  content: '';
  position: absolute;
  width: 8%;
  height: 68%;
  z-index: -1;
  border: 4px dotted red;
  top: 20%;
  left: 44.6%;
}
.investment_coverage_chart_wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  padding: 3px;
  position: relative;
}
.investment_coverage_chart_wrapper::after {
  content: '';
  position: absolute;
  width: 8%;
  height: 60%;
  z-index: -1;
  border: 4px dotted red;
  top: 20%;
  left: 45.6%;
}
.toggleSwitchWrapper {
  height: 40px;
  width: 220px;
  position: relative;
}
.exportCSVWrapper {
  margin-bottom: 30px;
}
.chartTitle {
  margin: 0;
  text-align: start;
}
.toggleSwitch_ruble {
  position: absolute;
  top: -40px;
  left: 695px;
}
.toggleSwitch_all_group {
  position: absolute;
  top: -10px;
  left: 695px;
}
.loadingBtn {
  margin-top: 30px;
  position: relative;
}
.saveBtn {
  margin-top: 30px;
  position: relative;
}
.loadingBtnsWrapper {
  position: absolute;
  right: -35px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 220px;
  background-color: white;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingBtns {
  border: 4px dotted grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  height: 80%;
}
.icon {
  margin-right: 5px;
}
.frequencyWrapper {
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  line-height: 1;
  background-color: white;
  position: absolute;
  top: -40px;
  left: 490px;
}
.frequencyValue {
  border: 1px solid black;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.knowledgeBlock {
  height: 250px;
  width: 1000px;
  background-color: #f2f2f2;
}
.knowledgeBlockTitle {
  margin-bottom: 30px;
}
.knowledgeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.knowledgeWrapper::after {
  content: '';
  position: absolute;
  width: 8%;
  height: 120%;
  z-index: -1;
  border: 4px dotted red;
  top: -12%;
  left: 44%;
  z-index: 100;
}
