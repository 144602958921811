.step2Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper {
  /* min-width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.mediaChoiceBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  margin: 10px 30px 0px 30px;
}
.resetBtnWrapper {
  margin-bottom: 30px;
}
.monthBlock {
  margin-top: 15px;
}
.monthSelectWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.monthSelectBorder {
  width: 300px;
  position: relative;
  border: 2px solid grey;
  border-radius: 20px;
}
.monthSelectWrapperTitle {
  position: absolute;
  top: -18%;
  left: 45%;
}
.changeCoeffWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px;
}
