.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 180px;
  border: 2px solid grey;
  border-radius: 30px;
}
.blockTitle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -12%;
  left: 23%;
}
.blockContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkboxWrapper {
  min-height: 40px;
  display: flex;
}

.btnsBlock {
  display: flex;
  justify-content: center;
}
.btn {
  width: 50px;
  height: 30px;
  border: none;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  background-color: #d32f2f;
}

.btn:hover {
  background-color: #ab2424;
  border-color: #a02222;
}

.startBtn {
  border-end-start-radius: 10px;
  border-top-left-radius: 10px;
}
.lastBtn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
