.select {
  position: relative;
  display: block;
}
.select__head {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  border-radius: 100px;
  width: 100px;
  height: 20px;
  padding: 0.1rem 1.1rem;
  margin: 5px 5px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select__head_Red {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  border-radius: 100px;
  width: 100px;
  height: 20px;
  padding: 0.1rem 1.1rem;
  margin: 5px 5px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(253, 229, 229);
}
.select__head_Green {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  border-radius: 100px;
  width: 100px;
  height: 20px;
  padding: 0.1rem 1.1rem;
  margin: 5px 5px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(227, 252, 227);
}
.forTargetAudience {
  height: 40px;
}
.forCoeff {
  width: 70px;
  /* border-radius: 10px;
  height: 40px;
  font-size: 16px; */
}

.select__list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  max-height: 370px;
  overflow-y: auto;
}
.forContributionChart {
  width: 250px;
}
.severalFlights {
  width: 95px;
  margin: 0;
  height: 40px;
}

.select__list .select__item {
  position: relative;
  padding: 3px 10px;
  list-style-type: none;
}
.select__list .select__item:hover {
  background-color: #c4c4c4;
}
.questionIconWrapper {
  position: absolute;
  left: 20px;
  top: 12px;
  width: 13px;
  height: 13px;
}
.infoCard {
  position: absolute;
  right: 150px;
  top: -45px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-size: 0.7rem;
  z-index: 1000;
  padding: 10px;
}
.disabled {
  box-shadow: none;
  pointer-events: none;
}
