.App {
  text-align: center;
}
.cellWithData {
  background-color: #d1e2ed;
}
.cellWithOutData {
  background-color: white;
}
.firstMonth {
  background-color: #767171;
  color: white;
  border: 3px solid white; 
  font-size: 11px;
}
.flightData {
  background-color: #738fa2;
  color: white
}
.firstMonthResult {
  background-color: white;
  color: white;
  font-size: 11px;
}

.flightDataResult {
  background-color: white;
  color: black
}
.cellWithOutDataResult {
  background-color: #D6DCE5
}