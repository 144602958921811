.stepButton {
  background-color: #d32f2f;
  min-height: 54px;
  min-width: 150px;
  border: none;
  color: white;
  font-size: 130%;
  border-radius: 10px;
  white-space: pre;
}
.stepButton:hover {
  background-color: #ab2424;
  border-color: #a02222;
}
.stepButton:disabled {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
.blueButton {
  background: linear-gradient(to top left, #356686 70%, #d1e2ed);
  box-shadow: 0 0 7px 7px rgb(0 0 0 / 20%);
  height: 80px;
  width: 150px;
  border: none;
  color: white;
  font-size: 120%;
  white-space: pre;
}
.blueButton:hover {
  background: linear-gradient(to top left, #083655 70%, #d1e2ed);
}
