.step1Wrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputParametersBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audienceBlock {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 10px;
}
.audienceBlockContent {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 10%;
  padding-top: 10px;
}
.settingsBlock {
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -10px;
}
.settingsBlockContent {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 10%;
  padding-top: 10px;
}
.iconWrapper {
  position: relative;
  width: 300px;
  height: 100px;
  border: 2px solid grey;
  border-radius: 30px;
}
.icon {
  background-color: white;
  color: gray;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  top: -25%;
  left: 40%;
}
.presenterBtn {
  position: relative;
}
.slaveBtn {
  position: absolute;
  top: 85px;
}
