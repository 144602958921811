.howItWorksBlock {
  margin-top: 30px;
  margin-bottom: 30xp;
}
.steps {
  display: flex;
  justify-content: space-evenly;
}

.startNewProjectBtnWrapper {
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
}
.link {
  text-decoration: none;
}
.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 10px;
}
.redTitle {
  color: rgb(225, 5, 0);
}
.icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(110, 109, 109);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.downloadBtnsBlock {
  position: absolute;
  top: 105%;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.downloadBtnWrapper {
  position: relative;
}

.forSeveralFlightsBtn {
  position: relative;
}
.forSeveralFlightsBtn::before {
  content: '';
  width: 1px;
  height: 102px;
  position: absolute;
  top: -6px;
  left: -30px;
  background-color: grey;
}
.forSeveralFlightsBtn::after {
  content: '';
  width: 30px;
  height: 2px;
  position: absolute;
  top: 30px;
  left: -30px;
  background-color: grey;
}
.forOneFlightBtn {
  margin-top: 5px;
  position: relative;
}
.forOneFlightBtn::before {
  content: '';
  width: 30px;
  height: 2px;
  position: absolute;
  top: 30px;
  left: -30px;
  background-color: grey;
}
