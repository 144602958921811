.span {
  display: block;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  min-width: 200px;
  text-align: center;
}
.input:focus {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  min-width: 200px;
  padding: 5px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.span100 {
  display: block;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  min-width: 100px;
  text-align: center;
}
.input100 {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  min-width: 100px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.input100:focus {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  width: 100px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.span100Red {
  display: block;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  min-width: 100px;
  text-align: center;
  background-color: rgb(253, 229, 229);
}
.input100Red {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  min-width: 100px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: rgb(253, 229, 229);
}

.span100Green {
  display: block;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  min-width: 100px;
  text-align: center;
  background-color: rgb(227, 252, 227);
}
.input100Green {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  min-width: 100px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: rgb(227, 252, 227);
}
.questionIconWrapper {
  position: absolute;
  left: 20px;
  top: 12px;
  width: 13px;
  height: 13px;
}
.infoCard {
  position: absolute;
  right: 250px;
  top: -20px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  font-size: 0.7rem;
  z-index: 1000;
  padding: 10px;
}
.infoCardForSmallSpan {
  position: absolute;
  right: 150px;
  top: -0px; 
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  font-size: 0.7rem;
  z-index: 1000;
  padding: 10px;
}
.disabled {
  box-shadow: none;
}
.severalFlights {
  height: 40px;
  margin: 0;
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.severalFlights:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  width: 95px;
  margin: 0;
}
