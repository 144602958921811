.warningCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: red;
  color: white;
  font-size: 18px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 30vw;
  margin-bottom: 40px;
}
.warning {
  background-color: rgb(250, 250, 250, 0.4);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.active {
  opacity: 1;
  pointer-events: all;
}
