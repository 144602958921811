.audienceAccordionDetails {
  background-color: white;
  position: absolute;
  top: 45px;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  max-height: 370px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
}
.audienceAccordionDetailsWrapper {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.accordionHead {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  border-radius: 100px;
  padding: 0.1rem 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  margin: 0;
  height: 40px;
  background-color: rgb(253, 229, 229);
}
.accordionHeadGreen {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  border-radius: 100px;
  padding: 0.1rem 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  margin: 0;
  height: 40px;
  background-color: rgb(227, 252, 227);
}
.accordionWrapper {
  position: relative;
}

.disabled {
  box-shadow: none;
}
.left {
  right: 10px;
}
