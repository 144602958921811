.blockWrapper {
  margin: 10px;
  position: relative;
  width: 1000px;
  border: 2px solid grey;
  border-radius: 30px;
}
.blockTitleStart {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -2%;
  left: 8%;
}
.blockTitleGroup {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -2%;
  left: 28%;
}

.blockTitleMiddle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -2%;
  left: 55%;
}
.blockTitleEnd {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 80%;
}
.blockContent {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  /* overflow-y: scroll; */
  position: relative;
}
.prompt {
  font-weight: bold;
  position: absolute;
  writing-mode: vertical-lr;
  height: 92%;
  top: 5%;
  left: 45%;
  display: flex;
  justify-content: space-between;
  transform:rotate(180deg);
}
.promptCRT{
  display: flex;
  justify-content: center;
  background-color: #81A9C9;
  height: 37%;
}
.promptCPM {
  display: flex;
  justify-content: center;
  background-color: #CAD3DC;
  height: 73%;
}
.item {
  display: flex;
  justify-content: center;
  min-height: 30px;
}
.checkboxWrapper {
  width: 450px;
  display: flex;
}
.mediaCheckboxWrapper {
  width: 250px;
  display: flex;
}
.groupCheckboxWrapper {
  display: flex;
  width: 150px;
}
.editableSpanWrapper {
  position: relative;
}
.editableSpanWrapper::before {
  content: '...';
  width: 24px;
  position: absolute;
  top: -20px;
  left: -43px;
  font-size: 40px;
  color: #c4c4c4;
}
.editableSpanWrapper2 {
  position: relative;
}
.editableSpanWrapper2::before {
  content: '...';
  width: 24px;
  position: absolute;
  top: -20px;
  left: -23px;
  font-size: 40px;
  color: #c4c4c4;
}
.digitalGroupCheckboxWrapper {
  position: absolute;
  top: 60.5%;
}
.oonGroupCheckboxWrapper {
  position: absolute;
  top: 26%;
}
.text_muted {
  color: #868e96;
  pointer-events: none;
  user-select: none;
}
.text_muted_for_checkbox {
  color: #868e96;
  pointer-events: auto;
  user-select: auto;
}
