.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 150px;
  border: 2px solid grey;
  border-radius: 30px;
}
.blockTitle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -12%;
  left: 17%;
}
.blockContent {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p {
  margin: 0;
  font-size: 0.8em;
}
