.blockWrapper {
  margin: 10px;
  position: relative;
  width: 1000px;
  border: 2px solid grey;
  border-radius: 30px;
  height: 300px;
}
.blockTitleStart {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 5%;
}
.blockTitleGroup {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 17%;
}

.blockTitleMiddle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 42%;
}
.blockTitleEnd {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -4%;
  left: 64%;
}
.blockContent {
  margin: 40px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  height: 250px;
  position: relative;
  overflow-x: auto;
}
.item {
  display: flex;
  justify-content: flex-end;
  min-height: 30px;
}
.checkboxWrapper {
  width: 350px;
  display: flex;
}
.mediaCheckboxWrapper {
  width: 230px;
  display: flex;
}
.groupCheckboxWrapper {
  display: flex;
  width: 90px;
}
.editableSpanWrapper {
  position: relative;
  /* white-space: pre; */
}
.editableSpanWrapper::before {
  content: '......';
  width: 24px;
  position: absolute;
  top: -20px;
  left: -46px;
  font-size: 40px;
  color: #c4c4c4;
}
.digitalGroupCheckboxWrapper {
  position: absolute;
  top: 60.5%;
}
.oonGroupCheckboxWrapper {
  position: absolute;
  top: 26%;
}
.text_muted {
  color: #868e96;
  pointer-events: none;
  user-select: none;
}
.text_muted_for_checkbox {
  color: #868e96;
  pointer-events: auto;
  user-select: auto;
}
