.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 180px;
  border: 2px solid grey;
  border-radius: 30px;
}
.blockTitle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -12%;
  left: 23%;
}
.blockContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.checkboxWrapper {
  min-height: 40px;
  display: flex;
  margin-left: 40px;
}
