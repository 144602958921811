.input {
  display: block;
  padding: 5px;
  margin: 5px;
  padding: 0.5rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 100px;
  text-align: center;
}
.input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
}

.flightsInput {
  margin: 0;
  padding: 0.1rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 95px;
  height: 40px;
  text-align: center;
}
.flightsInput:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
}
.disabled {
  box-shadow: none;
  color: #868e96;
}
