.wrapper {
  text-align: start;
}
.stepsList {
  list-style-type: decimal;
}
.sublist {
  list-style-type: lower-alpha;
}
.title {
  text-align: center;
}
.questionsBox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.background {
  position: absolute;
  top: 86px;
  right: 0;
}
.instruction {
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  margin: 50px;
  padding: 50px;
}
.tableRow {
  display: flex;
}
.tableRow div {
  border: 1px solid black;
  width: 100px;
  text-align: center;
}
.linkWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px;
  padding: 50px;
}
.questionLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
  text-align: start;
}
.link a {
  font-size: large;
}
.link a:hover {
  color: #d32f2f;
  cursor: pointer;
}
