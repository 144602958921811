.item {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  /* min-height: 30px; */
}
.checkboxWrapper {
  width: 450px;
  display: flex;
}
.mediaCheckboxWrapper {
  width: 250px;
  display: flex;
}
.groupCheckboxWrapper {
  display: flex;
  width: 150px;
}

.digitalGroupCheckboxWrapper {
  position: absolute;
  top: 60.5%;
}
.oonGroupCheckboxWrapper {
  position: absolute;
  top: 26%;
}
.text_muted {
  color: #868e96;
  pointer-events: none;
  user-select: none;
}
.text_muted_for_checkbox {
  color: #868e96;
  pointer-events: auto;
  user-select: auto;
}
