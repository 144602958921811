.knowledgeWrapper {
  display: flex;
  width: 79%;
  justify-content: space-between;
  margin-right: 30px;
}
.knowledgeItem {
  background-color: white;
  width: 65px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  font-size: 20px;
}
.separator {
  width: 65px;
  height: 3px;
  background: linear-gradient(to right, #a3b5c1, #263e4e);
}
.knowledgeText {
  font-size: 14px;
}
