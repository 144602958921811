.step5Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flowTable {
  table-layout: fixed;
  border-collapse: collapse;
  width: 1100px;
}
.resultTable {
  table-layout: fixed;
  border-collapse: collapse;
  width: 1100px;
  position: relative;
}
.thead .th:nth-child(1) {
  width: 50px;
}
.thead .th:nth-child(2) {
  width: 200px;
}
.thead .th:nth-child(16) {
  width: 100px;
}

.thead .thResult:nth-child(1) {
  width: 50px;
}
.thead .thResult:nth-child(2) {
  width: 200px;
}
.thead .thResult:nth-child(16) {
  width: 103px;
}
.flowTableBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.downloadBtnTextWrapper {
  display: flex;
  align-items: center;
}

.mediaCell {
  background-color: #e3e9ec;
}
.th {
  background-color: #767171;
  color: white;
  border: 3px solid white;
  font-size: 11px;
}
.thResult {
  background-color: #767171;
  color: white;
  /* border: 3px solid white; */
  font-size: 11px;
}
.flightsResult {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 5px;
  width: 900px;
}
.flightCard {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  height: 110px;
  font-size: 12px;
  min-width: 84px;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;  
}
.total {
  min-width: 200px !important;
  font-size: 25px;
  font-weight: bold;
}
.redLine {
  position: absolute;
  top:11px;
  right: -1px;
  width: 1045px;
  height: 5.9px;
  background-color: #d32f2f;
}
.table {
  margin-bottom: 20px;
}