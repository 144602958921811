.downloadButton {
  background-color: white;
  min-height: 60px;
  min-width: 320px;
  border: none;
  color: black;
  font-size: 100%;
  white-space: pre;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 50px;
}
.downloadButton:hover {
  background-color: rgb(200, 200, 209);
  border-color: rgb(200, 200, 209);
}
.downloadButton:disabled {
  background-color: rgb(237, 237, 238);
  border-color: rgb(237, 237, 238);
}
