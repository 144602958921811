.step2Wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mediaChoiceBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resetBtnWrapper {
  margin-bottom: 30px;
}
.monthSelectWrapper {
  width: 200px;
  text-align: center;
  margin-bottom: 200px;
  position: absolute;
  top: -170%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btnsBlock {
  display: flex;
  width: 1250px;
  justify-content: space-between;
}
.TABlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  color: white;
  width: 40%;
}
.generalInputDataBlock {
  background: linear-gradient(to right, #356686, #d1e2ed 70%);
  display: flex;
  justify-content: space-between;
  width: 1150px;
  min-height: 80px;
  font-size: 1.5rem;
}
.geoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  width: 40%;
}
.wrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkBoxWrap {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
