.coeffTable {
  width: 90% !important;
  border: 10px solid #d9d9d9;
  table-layout: fixed;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}
.thead {
  background-color: #e10500;
  color: white;
}
.thead .th:nth-child(1) {
  width: 20%;
}

.th,
.td {
  border: 1px solid white;
}
.coeffTable .tbody tr:nth-child(2n + 1) {
  background: #f4cccb;
}
.coeffTable .tbody tr:nth-child(2n + 2) {
  background: #f9e7e7;
}
.text_muted {
  color: #868e96;
  pointer-events: none;
  user-select: none;
}
