.stepButton {
  background-color: grey;
  min-height: 54px;
  min-width: 150px;
  border: none;
  color: white;
  font-size: 130%;
  border-radius: 10px;
}
.stepButton:hover {
  background-color: rgb(94, 94, 94);
  border-color: rgb(94, 94, 94);
}
.stepButton:disabled {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
