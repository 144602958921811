.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 150px;
  border: 2px solid grey;
  border-radius: 30px;
  position: relative;
}
.blockTitle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -12%;
  left: 13%;
}
.blockContent {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.checkboxWrapper {
  min-height: 40px;
  display: flex;
  margin-left: 35px;
}
.budgetValueWrapper {
  display: none;
  position: absolute;
  top: 22%;
  left: 29%;
}
.coverageValueWrapper {
  display: none;
  position: absolute;
  top: 48%;
  left: 29%;
}
.different_budgetValueWrapper {
  display: none;
  position: absolute;
  top: 59%;
  left: 29%;
}
.visible {
  display: block;
}
