.stepWrapper {
  width: 300px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  white-space: pre;
}
.stepNumber {
  width: 40px;
  padding: 5px;
  background-color: #d32f2f;
  color: white;
  font-size: 170%;
  border-radius: 100%;
  margin-bottom: 20px;
}
.stepTitle {
  font-weight: 700;
  margin-bottom: 20px;
}
