.blockWrapper {
  margin: 10px;
  position: relative;
  width: 850px;
  border: 2px solid grey;
  border-radius: 30px;
}
.blockTitleStart {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -6%;
  left: 8%;
}
.blockTitleMiddle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -6%;
  left: 41%;
}
.blockTitleEnd {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -6%;
  left: 78%;
}
.blockContent {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  height: 180px;
  overflow-y: auto;
}
.item {
  display: flex;
  justify-content: space-evenly;
  min-height: 32px;
}
.checkboxWrapper {
  width: 250px;
  display: flex;
}
.editableSpanWrapper {
  position: relative;
}
.editableSpanWrapper::before {
  content: '........';
  width: 24px;
  position: absolute;
  top: -20px;
  left: -62px;
  font-size: 40px;
  color: #c4c4c4;
}
.mediaName {
  width: 220px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  text-align: start;
}
