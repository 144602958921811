.bar {
  background-color: 'white';
  color: 'black';
  width: '100%';
  overflow: 'hidden';
  height: 84px;
  position: relative;
}

.barContent {
  display: flex;
  justify-content: flex-end;
  height: 84px;
}
.logo {
  display: flex;
  align-items: flex-end;
  margin-bottom: 60px;
}
.logo span {
  color: #d32f2f;
}
.tabbedBlock {
  margin-right: 30px;
  margin-left: 30px;
  display: flex;
  align-items: flex-end;
}
.headerButton {
  height: 44px;
  width: 200px;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 120%;
  z-index: 1100;
}
.active {
  background-color: rgb(110, 109, 109);
  height: 54px;
  color: white;
  font-size: 130%;
}
.delimiterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -2%;
  right: 0;
  left: 0;
}
@media screen and (min-width: 1600px) {
  .delimiterWrapper {
    left: 5%;
  }
}
@media screen and (min-width: 1700px) {
  .delimiterWrapper {
    left: 8%;
  }
}
@media screen and (min-width: 1800px) {
  .delimiterWrapper {
    left: 10%;
  }
}
@media screen and (min-width: 1900px) {
  .delimiterWrapper {
    left: 12%;
  }
}
