.span {
  display: block;
  padding: 5px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  min-width: 100%;
  text-align: center;
}
.input {
  outline: 0;
  text-align: center;
  min-width: 100%;
  padding: 5px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.input:focus {
  outline: 0;
  text-align: center;
  width: 100%;
  border: none;
  padding: 5px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
