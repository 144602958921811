.startNewProjectBtnWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 10px;
}
.redTitle {
  color: rgb(225, 5, 0);
}
.icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(110, 109, 109);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.goBackBtnWrapper {
  position: absolute;
  top: 30%;
  left: -45%;
}
