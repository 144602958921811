.step3Wrapper {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chartsWrapper {
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin: 10px 30px 0px 30px;
}
.pieCharts {
  display: flex;
}
.pieChartWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  padding: 3px;
  width: 45%;
}
.contributionChartWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  padding: 3px;
  width: 45%;
}
.opticChartWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  padding: 3px;
  position: relative;
}
.opticChartWrapper::after {
  content: '';
  position: absolute;
  width: 8%;
  height: 68%;
  z-index: -1;
  border: 4px dotted red;
  top: 20%;
  left: 44.6%;
}
.toggleSwitchWrapper {
  height: 40px;
  width: 200px;
  position: relative;
}
.exportCSVWrapper {
  margin-bottom: 30px;
}
.chartTitle {
  margin: 0;
  text-align: start;
}
.toggleSwitch_ruble {
  position: absolute;
  top: -40px;
  left: 695px;
}
.toggleSwitch_all_group {
  position: absolute;
  top: -10px;
  left: 695px;
}
.toggleSwitch_ruble_pie {
  position: absolute;
  top: -40px;
  left: 140px;
}
.toggleSwitch_all_group_pie {
  position: absolute;
  top: -10px;
  left: 140px;
}
.toggleSwitch_all_group_contribution {
  position: absolute;
  top: -60px;
  left: 140px;
}
.loadingBtn {
  margin-top: 30px;
  position: relative;
}
.saveBtn {
  margin-top: 30px;
  position: relative;
}
.loadingBtnsWrapper {
  position: absolute;
  right: -25px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 220px;
  background-color: white;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingBtns {
  border: 4px dotted grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  height: 80%;
}
.icon {
  margin-right: 5px;
}
.selectWrapper {
  position: absolute;
  top: -10px;
  left: 130px;
}
.selectTitle {
  position: absolute;
  top: -20px;
  left: 0px;
}
.ohvatLegend {
  border: black 2px solid;
}
.knowledgeItem {
  background-color: white;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  background-color: rgb(217, 217, 217);
  font-size: 22px;
}
.separator {
  width: 65px;
  height: 3px;
  background: linear-gradient(to right, #a3b5c1, #263e4e);
}
.knowledgeText {
  font-size: 16px;
}
.knowledgeText2 {
  font-size: 16px;
  font-weight: bold;
}
.knowledgeTitle {
  font-size: 20px;
}

.blockWrapper {
  margin: 10px;
  position: relative;
  width: 200px;
  height: 180px;
  border: 2px solid grey;
  border-radius: 30px;
}
.blockTitle {
  color: #d32f2f;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: -12%;
  left: 18%;
}
.blockContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.presenterBtn {
  position: relative;
}
.knowledgeGeneralCategoryWrapper {
  position: absolute;
  bottom: 75px;
  left: -11px;
}
.slaveBtn {
  position: absolute;
  top: 55px;
}
.slaveBtn2 {
  position: absolute;
  top: 140px;
}
